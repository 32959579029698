import logo from './logo-ijb.png';
import logoYtb from './youtube.svg';
import logoInsta from './instagram.svg';
import logoFb from './facebook.svg';
import logoLinkedin from './linkedin.svg';
import frase from './frase.svg';
import googleButton from './play-store.png'
import videoAprendizVertical from './video-vertical-baixa.mp4';
import videoAprendizHorizontal from './video-horizontal.mp4';
import './App.css';
import React, { useEffect, useState } from 'react';

function App() {

    function videoElem() {
        const elem = document.getElementById("myvideo");
        if(elem) {
            function myHandler() {
                elem.remove();
            }
            elem.addEventListener('ended',myHandler,false)
        }
      }
      
    useEffect(() => {
        videoElem();
    }, [])

    const altura = window.screen.height;
    const largura = window.screen.width;

    return (
        <div className="App">
            <header className="App-header">
                {/* <iframe id='myvideo' width='100%' height={altura} style={{ marginBottom: 100 }} frameBorder="0"
                src="https://player.vimeo.com/video/736856845?h=08f7cff4da">
                </iframe> */}
                <img src={logo} className="App-logo" alt="logo" />
                <p className='App-accent'>
                    Seja muito bem-vindo(a) ao
                </p>
                <p className='App-title'>
                    Instituto João Bittar.
                </p>
                <div style={{ display: 'flex', flexDirection: 'column', padding: 20, marginTop: 26 }}>
                    <img src={frase} style={{ maxWidth: 342, maxHeight: 137 }} alt="frase inicial: É uma grande alegria para nós iniciar essa jornada ao seu lado. A partir de agora, você faz parte de nossa história, e queremos que saiba que pode contar conosco ao longo do percurso." />
                </div>
                <iframe width='100%' height={altura} style={{ marginBottom: 10 }} frameBorder="0"
                src="https://player.vimeo.com/video/736856845?h=08f7cff4da">
                </iframe>
                <div>
                    <h6 className='App-paragraph-2'>Vamos dar o próximo passo juntos?</h6>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: -20, justifyContent: 'space-around', width: '100%' }}>
                        <img src={googleButton} onClick={() => window.location.href = "https://play.google.com/store/apps/details?id=com.bittareducacao.ijbaprendiz"} style={{ maxWidth: 163, maxHeight: 56, cursor: 'pointer' }} alt="botão download para android" />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 3 }}>
                    <h6 className='App-paragraph'>Siga o Instituto João Bittar nas redes sociais</h6>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', marginBottom: 30, padding: '0px 100px', marginTop: -15  }}>
                    <img onClick={() => window.location.href = "https://www.youtube.com/c/SocietaFormacaoProfissional"} src={logoYtb} className="App-social" alt="logo-youtube" />
                    <img onClick={() => window.location.href = "https://www.instagram.com/institutojoaobittar/"} src={logoInsta} className="App-social" alt="logo-instagram" />
                    <img onClick={() => window.location.href = "https://pt-br.facebook.com/institutojoaobittar/"} src={logoFb} className="App-social" alt="logo-facebook" />
                    <img onClick={() => window.location.href = "https://br.linkedin.com/company/institutojoaobittar"} src={logoLinkedin} className="App-social-end" alt="logo-linkedin" />
                    </div>
                </div>
            </header>
        </div>
    );
}

export default App;
